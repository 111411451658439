import { IDEdgeContext, Environments } from "../models/context";
import { IClientEvent } from "../models/event";
import { ITrackerClient } from "../models/trackerClient";

export class GtmTrackerClient implements ITrackerClient {
    private readonly _dEdgeContext: IDEdgeContext;
    private readonly _gtmId: string;

    public constructor(dEdgeContext: IDEdgeContext) {
        this._dEdgeContext = dEdgeContext;
        this._gtmId = this.getGtmConfigurations(dEdgeContext.environment);
    }

    public initialize() {
        this.appendNoScriptElement();
        this.appendScriptElement();
        this.startGtm();
    }

    public canTrack(): boolean {
        // If window.dataLayer is undefined,
        // that means the google tag manager script failed to load
        return window.dataLayer !== undefined;
    }

    private appendNoScriptElement(): void {
        const noScriptElement = document.createElement("noscript");
        noScriptElement.innerHTML = `<iframe
            src="https://www.googletagmanager.com/ns.html?id=${this._gtmId}"
            id="gtm"
            height="0"
            width="0"
            style="display:none;visibility:hidden"></iframe>`;
        document.getElementsByTagName("body")[0].prepend(noScriptElement);
    }

    private appendScriptElement(): void {
        const scriptElement = document.createElement("script");
        scriptElement.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', "${this._gtmId}");
        `;

        document.getElementsByTagName("head")[0].prepend(scriptElement);
    }

    private startGtm(): void {
        if (!this.canTrack()) {
            console.warn("Could not start GTM because window.google_tag_manager is not defined");
            return;
        }

        window.dataLayer.push({ dEdgeContext: this._dEdgeContext, page: this._dEdgeContext.defaultPage });
        window.dataLayer.push({
            "gtm.start": new Date().getTime(),
            event: "trackingReady"
        });
    }

    public addEvent(event: IClientEvent): void {
        if (!this.canTrack()) {
            console.warn("Could push GTM event because window.google_tag_manager is not defined");
            return;
        }

        window.dataLayer.push(event);
        this.cleanupForNextEventCalls();
    }

    /**
     * As GTM regularly send event, some information has to be cleaned up
     * to avoid polluting those event that are not pushed by the client.
     */
    private cleanupForNextEventCalls(): void {
        window.google_tag_manager?.[`${this._gtmId}`].dataLayer.set("data", undefined);
        window.google_tag_manager?.[`${this._gtmId}`].onHtmlSuccess("gtm");
    }

    private getGtmConfigurations(environment: Environments): string {
        switch (environment.toLowerCase()) {
            case "staging":
                return "GTM-P2NV9QW";
            case "production":
                return "GTM-MWZ5V9B";
            case "bo-staging":
                return "GTM-P9SGK5PV";
            case "bo-production":
                return "GTM-MWZ5V9B";
            default:
                throw new Error("Unknown environment");
        }
    }
}
