import Helper from "../helper";
import { IDEdgeContext } from "../models/context";
import { IEvent } from "../models/event";
import { ITrackerClient } from "../models/trackerClient";
import { GtmTrackerClient } from "../tracker-clients/gtmTrackerClient";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any[];
        google_tag_manager?: {
            // eslint-disable-next-line @typescript-eslint/ban-types
            [x: string]: {
                dataLayer: { set: (arg1: string, arg2: any) => void };
                onHtmlSuccess: (arg1: string) => void;
            };
        };
        Tracker: {
            initialize: typeof Tracker.initialize;
            getInstance: typeof Tracker.getInstance;
            trackEvent: typeof Tracker.trackEvent;
        };
    }
}

/**
 * It allows to track information across D-edge apps
 */
export class Tracker {
    private _trackerClient: ITrackerClient;
    private static _tracker: Tracker;

    private constructor(dEdgeContext: IDEdgeContext) {
        this._trackerClient = new GtmTrackerClient(dEdgeContext);
    }

    /**
     * Initialize the tracking
     * @param {IDEdgeContext} dEdgeContext Shared context within D-edge systems
     */
    static initialize(dEdgeContext: IDEdgeContext): void {
        if (this._tracker) return;
        this._tracker = new Tracker(dEdgeContext);
        this._tracker._trackerClient.initialize();
    }

    /**
     * Provides a DEdgeTracker on condition to have initialized it first
     * by calling DEdgeTracker.initialize
     * @returns {Tracker} The only instance of DEdgeTracker
     */
    static getInstance(): Tracker {
        if (this._tracker) return this._tracker;
        throw "Initialize tracker first by calling Tracker.initialize";
    }

    /**
     * Track a specific event
     * @param {IEvent} event The event
     */
    static trackEvent<IData, IPage>(event: IEvent<IData, IPage>): void {
        const clientEvent = Helper.buildEvent<IData, IPage>(event);
        if (this._tracker._trackerClient.canTrack()) {
            this._tracker._trackerClient.addEvent(clientEvent);
        }
    }

    /**
     * Checks tracking ability
     * @returns a boolean indicating whether the tracker is able to to track events
     */
    static canTrack(): boolean {
        return this._tracker._trackerClient.canTrack();
    }
}

window.Tracker = {
    initialize: (dEdgeContext: IDEdgeContext) => Tracker.initialize(dEdgeContext),
    getInstance: () => Tracker.getInstance(),
    trackEvent: <IData, IPage>(event: IEvent<IData, IPage>) => Tracker.trackEvent(event)
};
