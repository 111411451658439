import { IClientEvent, IEvent } from "./models/event";

export default class Helper {
    static setDataLayer() {
        window.dataLayer = window.dataLayer || [];
    }

    static buildEvent<IData, IPage>(event: IEvent<IData, IPage>): IClientEvent {
        const { eventName, data, ...rest } = event;
        return {
            event: eventName,
            data,
            ...rest
        };
    }
}
